.cards-wrapper {
    display: flex;
    max-width: 1200px;
    margin: 20px auto;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 10px 20px;
    width: 300px;
}

.staff-wrapper {
    margin-top: 80px;
    text-align: center;
}

.card img {
    width: 150px;
    border-radius: 50%;
}

.card div {
    border: 1px solid rgba(128, 128, 128, 0.584);
    width: 160px;
    height: 160px;
    border-radius: 50%;
    display: grid;
    justify-content: center;
    align-items: center;
}

.card p, .card h3{
    margin: 5px;
}

.card h2{
    margin-top: 20px;
}