.header-text {
    background-color: var(--main-bg-color);
}

.button-container {
  margin-top: 40px;
}

.applybutton {
	color: white;
	background-color: var(--main-color);
	border: 4px solid transparent;
	transition: .4s ease;
	border-radius: 50px;
  text-decoration: none;
  padding: 5px 20px;
  font-size: 20px;
  font-weight: 700;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.311);
}

.applybutton:hover {
	color: var(--main-color);
	background-color: var(--main-bg-color);
	transform: scale(1.1);
	border: 4px solid var(--main-color);
  box-shadow: none;
}

.herelink {
  text-decoration: none;
  font-weight: 500;
}

.herelink:hover {
  color: var(--accent-color);
}

.hero-wrapper {
  margin: auto;
}

.header h1, h2 {
  font-weight: 400;
}

.hero-flex-wrapper {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  flex-wrap: wrap; 
}

.flex-item {
  padding: 20px 30px;
  align-self: center;
}

.desc h1 {
  font-size: 60px;
  font-weight: 400;
}

.desc {
  max-width: 550px;
}

h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 0.5rem;
    background-color: transparent;
  }

.ddoski-hero {
  max-width: 500px!important;
}

.typing-text {
  color: #DF5F17;
  font-weight: 500;
}

.desc-section {
  padding: 40px 0;
  color: var(--main-bg-color);
  background-color: var(--main-color);
}

.spons-section {
  padding: 40px 0;
  background-color: var(--main-bg-color);
}

.spons-section h2 {
  font-size: 40px;
  margin: 0;
}

.support-logo {
  margin: 20px 10px;
  height: 50px;
}

.desc-section h2 {
  font-size: 40px;
  margin: 0;
}


.desc-text {
  margin: auto;
  padding: 0 20px;
  max-width: 47rem;
}

.partner-logo {
  margin: 20px;
  max-width: 100px;
}

.bessa {
  border-radius: 25px;
}

.footer {
  text-align: center;
  background-color:var(--accent-color);
  color: var(--main-bg-color);
  font-weight: 500;
  padding: 20px 0 ;
}

a {
  color: var(--main-color);
  text-decoration: none;
}

#cubstart-img {
  max-width: 400px;
  width: 90vw;
  margin: 10px 0;
  border-radius: 40px;
}

.info-section a {
  color : var(--main-bg-color);
}

@media only screen and (max-width: 600px) {
  .desc h1 {
    font-size: 40px!important;
  }

  .ddoski-hero {
    max-width: 300px!important;
  }

  .hero-flex-wrapper {
    display: flex;
    padding: 20px 0;
    justify-content: center;
    flex-wrap: wrap; 
  }
}

.stats-wrapper {
  margin: auto;
  display: flex;
  padding: 20px 0;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
}

.stats-item {
  width: 125px;
  transition: 0.3s;
}

.stats-item img{
  height: 130px;
}

.stats-item:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

.sponsor-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sponsor-packet {
  display: grid;
  justify-content: center;
}

.sponsor-packet iframe {
  height: 100vh;
  width: 100vw;
}

.banner {
  background-color: var(--main-color);
  color: white;
  font-weight: bold;
  height:45px;
  padding-top:0px;


  text-align: center;
  font-size: 20px;
  border-top: black solid 1px;
  border-bottom: black solid 1px;
}