* {
    box-sizing: border-box;
  }

  /* TODO: should probably use a reset or normalize stylesheet instead */
  html, body, form, fieldset, table, tr, td, img, input, button, select, textarea, optgroup, option {
    font-family: 'Open Sans', sans-serif;
  }

  html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

  /* BASIC SETUP */
  
  .page-wrapper {
    width: 100%;
    height: auto;
  }
  
  .nav-wrapper {
    width: 100%;
    min-height: 1300px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: var(--main-bg-color);
  }

  #wave {
    bottom: 0;
    position: absolute;
    z-index: -1;
  }
  
  #coding-ddoski {
    width: 20%;
    position: absolute;
    opacity: 0.4;
  }

  table {
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    /* white-space: nowrap; */
  }

  .grad-bar {
    width: 100%;
    height: 5px;
    background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    background-size: 400% 400%;
        -webkit-animation: gradbar 15s ease infinite;
      -moz-animation: gradbar 15s ease infinite;
      animation: gradbar 15s ease infinite;
  }
  
  /* LOGO */
  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-wrapper h3 {
    margin-top: 10px;
    float: right;
    font-weight: 100;
    font-size:0.85em;
    line-height:0.12;
    width:175px;
    color: #a1a1a1; /* var(--main-color);*/
  }

  .logo-wrapper .subheader h3 {
    margin-top: 10px;
  }

  #logo {
    height: 80px;
  }
  /* NAVIGATION */
  
  .navbar {
    display: grid;
    grid-template-columns: 0.5fr 3fr;
    align-items: center;
    overflow: hidden;
    max-width: 1650px;
    margin: auto;
  }

  .navbar ul {
    /* position: absolute; */
    margin-right: 8%;
    list-style: none;
    display: flex;
    justify-self: end;
    display: -webkit-flex;
    -webkit-justify-self: end;
  }
  
  .nav-item {
    padding-right: 20px;
  }

  .nav-item a {
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    transition: color 0.3s ease-out;
  }
  
  .nav-item a:hover {
    color: #3498db;
  }
  
  /* MOBILE MENU & ANIMATION */
  
  .menu-toggle .bar{
    width: 25px;
    height: 3px;
    background-color: #3f3f3f;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .menu-toggle {
    justify-self: end;
    display: none;
  }
  
  .menu-toggle:hover{
    cursor: pointer;
  }
  
  #mobile-menu.is-active .bar:nth-child(2){
    opacity: 0;
  }
  
  #mobile-menu.is-active .bar:nth-child(1){
    -webkit-transform: translateY(8px) rotate(45deg);
    -ms-transform: translateY(8px) rotate(45deg);
    -o-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }
  
  #mobile-menu.is-active .bar:nth-child(3){
    -webkit-transform: translateY(-8px) rotate(-45deg);
    -ms-transform: translateY(-8px) rotate(-45deg);
    -o-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
  }
  
  /* KEYFRAME ANIMATIONS */
  
  @-webkit-keyframes gradbar {
      0% {
          background-position: 0% 50%
      }
      50% {
          background-position: 100% 50%
      }
      100% {
          background-position: 0% 50%
      }
  }
  
  @-moz-keyframes gradbar {
      0% {
          background-position: 0% 50%
      }
      50% {
          background-position: 100% 50%
      }
      100% {
          background-position: 0% 50%
      }
  }
  
  @keyframes gradbar {
      0% {
          background-position: 0% 50%
      }
      50% {
          background-position: 100% 50%
      }
      100% {
          background-position: 0% 50%
      }
  }
  
  /* Media Queries */
  
    /* Mobile Devices - Phones/Tablets */
  
  @media only screen and (max-width: 720px) { 
    .features {
      flex-direction: column;
      padding: 50px;
    }
    
    /* MOBILE NAVIGATION */
       
    .navbar ul {
      display: flex;
      flex-direction: column;
      position: fixed;
      justify-content: flex-start;
      top: 80px;
      background-color: var(--main-bg-color);
      opacity: 0.9;
      width: 100%;
      transform: translate(-101%);
      text-align: center;
      overflow: hidden;
      margin-right: 0;
      padding-left: 0;
      padding-bottom: 10px;
    }
    
    .navbar li {
      padding: 15px;
    }
    
    .navbar li:first-child {
      margin-top: 50px;
    }
    
    .navbar li a {
      font-size: 1rem;
    }
     
    .menu-toggle, .bar {
      display: block;
      cursor: pointer;
    }
    
    .menu-toggle {
      right: 40px;
      top: 30px;
      position: fixed;
      z-index: 8;
    }
    .mobile-nav {
    transform: translate(0%)!important;
    z-index: 5;
    position: fixed;
    }
    }

    .nav-cubstart-text {
      font-family: "Oxygen", sans-serif;
      font-weight: 400;
      color: var(--main-color);
      font-size:1.35em;
    }

  .nav-join-team-bttn {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; 
    padding-left: 1rem;
    padding-right: 1rem; 
    border-radius: 0.25rem; 
    border-bottom-width: 4px; 
    border-right:0px;
    border-left:0px;
    border-top:0px;
    font-size: 0.9rem;
    line-height: 1.25rem; 
    font-weight: 700; 
    color: #ffffff; 
    transition-duration: 75ms; 
    transition-delay: 75ms; 
    background: #3D9BC2;
    border-color: #1F83AC;
    cursor:pointer;
}
.nav-join-team-bttn:hover { 
 background: #1F83AC;
 border-color: #177197;
 }

 .Navbar .banner {
  width:100%;
  font-size:1.03em;
  letter-spacing:0.01em;
  font-weight:600;
  font-family: "Oxygen", sans-serif;
  border:none;
  padding:12px;
  background-image: linear-gradient(to right, #66c8f2, #117ead);
  text-align:center;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
 }
 .banner .text {
  max-width:50%;
  display:block;
 }
 @media (max-width: 475px) {
  .Navbar .banner {
    display:block;
  }
  .banner .text {
    margin-left:0%;
    max-width:60%;
   }
 }
