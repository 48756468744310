@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
:root {
    --main-bg-color: rgb(255, 255, 255);
    --accent-bg-color: rgb(233, 246, 255);
    --main-color: #3e9ec4;
    --accent-color: #016f9b;
  }

.text-wrapper {
    max-width: 900px;
    margin: 3% auto;
    padding: 20px;
}

.heading {
    text-align: center;
}
